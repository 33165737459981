import { useQuery } from '@tanstack/react-query';

import { config } from '~/config';
import { Pagination, JsonApiResource, JsonApiResponseWithMeta } from '~/data/shared';
import { useRequest } from '~/lib/useRequest';
import { ApplicationStatus } from '~/data/types';
import { IsNullOrUndefined, hasKey } from '~/utils';

export type ApplicationSearchQuery = {
  'filter[q]'?: string;
  'filter[status]'?: string[];
  'filter[nationality]'?: string[];
  'filter[submissionDate][from]'?: string;
  'filter[submissionDate][to]'?: string;
  'page[size]': number;
  'page[number]': number;
};

export type Application = {
  id: string;
  status: ApplicationStatus;
  referenceNumber: string;
  dateSubmitted: Date;

  applicant: {
    firstName: string;
    middleName?: string;
    lastName?: string;
    nationality: string;
  };

  programIntake: {
    program: {
      name: string;
    };
    intakeTerm: {
      title: string;
    };
  };
};
type ApplicationResource = JsonApiResource<'application', Omit<Application, 'id'>>;

export type GetApplicationsListSearchResponse = JsonApiResponseWithMeta<
  Array<ApplicationResource>,
  { pagination: Pagination }
>;

export type RawApplicationsResponse = GetApplicationsListSearchResponse;

export function useApplications(
  query: ApplicationSearchQuery,
  options?: { onSuccess?: (data: Application[], total: number) => void }
) {
  const request = useRequest();
  const { isLoading, isFetching, error, data, isPreviousData } = useQuery(
    ['applications', query],
    async () => {
      const response = await request<RawApplicationsResponse>(
        `${config.apiHost}/private/applications_list/v1/applications?${convertToUrlSearchParams(query)}`,
        {
          method: 'GET',
          isExpectedResponse,
        }
      );
      const data = response.data;
      const applications = data.map((app) => {
        return {
          id: app.id,
          ...app.attributes,
          dateSubmitted: new Date(app.attributes.dateSubmitted),
        } as Application;
      });

      // trigger on success
      options?.onSuccess?.(applications, response.meta.pagination.total);

      return {
        // as we are using keepPreviousData, we need to know the result is based on which page
        pagination: {
          pageNumber: response.meta.pagination.pageNumber,
          pageSize: response.meta.pagination.pageSize,
        },
        applications,
        totalApplications: response.meta.pagination.total,
      };
    },
    {
      keepPreviousData: true,
    }
  );

  return {
    isPreviousApplicationsData: isPreviousData,
    applicationPagination: data?.pagination,
    applications: data?.applications || [],
    applicationsError: error,
    isFetchingApplications: isFetching,
    isLoadingApplications: isLoading,
    totalApplications: data?.totalApplications ?? 0,
  };
}

function isExpectedResponse(res: unknown): res is RawApplicationsResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res) && Array.isArray(res.data));
}

const convertToUrlSearchParams = (params: ApplicationSearchQuery, baseKey?: string) => {
  const queryParams = {} as Record<string, string>;
  Object.entries(params).forEach(([k, v]) => {
    const key = (baseKey && `${baseKey}[${k}]`) || k;
    if (IsNullOrUndefined(v)) {
      return;
    }
    if (typeof v === 'string' && v.length > 0) {
      queryParams[key] = v;
    } else if (typeof v === 'number') {
      queryParams[key] = `${v}`;
    } else if (typeof v === 'boolean' && v) {
      queryParams[key] = 't';
    } else if (Array.isArray(v) && v.length > 0) {
      queryParams[key] = v.join(',');
    }
  });
  return new URLSearchParams(queryParams);
};
