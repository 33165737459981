import { useQuery } from '@tanstack/react-query';
import { useRequest } from '~/lib/useRequest';

import { config } from '~/config';
import { Intent } from '~/utils';

export type ReferenceData = {
  nationalities: Record<string, { alpha3Code: string; nationalityLabel: string; countryLabel: string }>;
  statuses: Record<string, { label: string; intention: Intent }>;
  pageSizes: number[];
  programs: Array<unknown>; // todo: we will remove this later
};
export type InitialQueryParameter = {
  searchQuery?: string;
  pageSize: number;
  sort: string;
};
export type ApplicationsPageInitialProps = {
  referenceData: ReferenceData;
  initialQueryParameters: InitialQueryParameter;
};

export function useApplicationsPageProps() {
  const request = useRequest();
  const { isLoading, data } = useQuery({
    queryKey: ['useApplicationsPageProps'],
    queryFn: async () => {
      const response = await request<ApplicationsPageInitialProps>(
        `${config.apiHost}/private/applications_list/v1/page_props`,
        {
          isExpectedResponse,
        }
      );

      return response;
    },
  });

  return {
    initialPropsError: false,
    isLoadingInitialProps: isLoading,
    initialProps: data,
  };
}

function isExpectedResponse(res: unknown): res is ApplicationsPageInitialProps {
  return Boolean(res && typeof res === 'object');
}
