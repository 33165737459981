import { useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { queryParametersSchema, QueryParametersSchema, get, QueryParametersValue } from './../schema';
import { UnBoxArrayType } from '~/utils';
import { useStaticProps, useQueryParameter } from '../context';

type ParamKey = keyof QueryParametersSchema;

export function useSchema() {
  const { search } = useLocation();
  const staticProps = useStaticProps();
  const { queryParameters } = useQueryParameter();

  const parameters = useMemo(() => queryParametersSchema.parameters, []);
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const getParameter = useCallback(<K extends ParamKey>(name: K) => get(name), []);
  const getDefaultValue = useCallback(
    <K extends ParamKey>(name: K) =>
      get(name).getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter }),
    [staticProps]
  );
  const getValue = useCallback(
    <K extends ParamKey>(name: K) => get(name).getValue({ staticProps, queryParameters }),
    [queryParameters, staticProps]
  );
  const convertValueToUrlQueryString = useCallback(
    <K extends ParamKey>(name: K) => {
      const parameter = get(name);
      if (!parameter.toUrlSearchParams) return null;

      const value = getValue(name);
      const defaultValue = getDefaultValue(name);
      return parameter.toUrlSearchParams({
        defaultValue,
        value,
        staticProps,
        queryParameters,
      });
    },
    [queryParameters, staticProps, getValue, getDefaultValue]
  );
  const convertUrlQueryStringToParam = useCallback(
    <K extends ParamKey>(name: K) => {
      const parameter = get(name);
      if (!parameter.fromUrlSearchParams) return null;

      const defaultValue = getDefaultValue(name);
      return parameter.fromUrlSearchParams({ defaultValue, staticProps, urlSearchParams });
    },
    [staticProps, urlSearchParams, getDefaultValue]
  );
  const convertValueToSearchQuery = useCallback(
    <K extends ParamKey>(name: K) => {
      const parameter = get(name);
      if (!parameter.toSearchQuery) return null;

      const value = getValue(name);
      const defaultValue = getDefaultValue(name);
      return parameter.toSearchQuery({
        defaultValue,
        value,
        staticProps,
        queryParameters,
      });
    },
    [staticProps, queryParameters, getValue, getDefaultValue]
  );
  const sanitizeQueryParameter = useCallback(
    <K extends ParamKey>(name: K) => {
      const parameter = get(name);
      if (!parameter.sanitize) return null;

      const value = getValue(name);
      const defaultValue = getDefaultValue(name);
      return parameter.sanitize({
        defaultValue,
        value,
        staticProps,
        queryParameters,
      });
    },
    [staticProps, queryParameters, getValue, getDefaultValue]
  );

  const getSummaryItems = useCallback(
    <K extends ParamKey>(name: K) => {
      const parameter = get(name);
      if (!parameter.getSummaryItems) return null;

      const value = getValue(name);
      const defaultValue = getDefaultValue(name);
      return parameter.getSummaryItems({ defaultValue, value, staticProps, queryParameters });
    },
    [staticProps, queryParameters, getValue, getDefaultValue]
  );
  const removeQueryParameter = useCallback(
    <K extends ParamKey>(name: K, value: UnBoxArrayType<QueryParametersValue<K>>) => {
      const parameter = get(name);
      if (!parameter.removeParam) return null;

      const currentValue = getValue(name);
      const defaultValue = getDefaultValue(name);
      return parameter.removeParam({
        defaultValue,
        value: currentValue,
        staticProps,
        queryParameters,
        removedValue: value,
      });
    },
    [staticProps, queryParameters, getValue, getDefaultValue]
  );

  return {
    parameters,
    getValue,
    getParameter,
    getDefaultValue,
    getSummaryItems,
    removeQueryParameter,
    convertValueToUrlQueryString,
    convertUrlQueryStringToParam,
    convertValueToSearchQuery,
    sanitizeQueryParameter,
  };
}
