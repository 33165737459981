import React from 'react';
import { MultiSelect } from '@applyboard/crystal-ui';
import { useFilterField } from './../../hooks/useFilterField';

export const StatusFilter = () => {
  const {
    value,
    setValue,
    staticProps: { referenceData },
  } = useFilterField('status', 'PrimaryFilter');

  const statuses = React.useMemo(
    () => Object.entries(referenceData.statuses).map(([k, v]) => ({ label: v.label, value: k })),
    [referenceData.statuses]
  );

  return (
    <MultiSelect
      value={value}
      aria-label="Status"
      placeholder="Status"
      onChange={setValue}
      emphasis="highlighted"
      placeholderVariant="indicator"
    >
      {statuses.map((status) => (
        <MultiSelect.Option label={status.label} value={status.value} key={status.value} />
      ))}
    </MultiSelect>
  );
};
