import { Tag } from '@applyboard/crystal-ui';
import { Application } from '~/pages/ApplicationDetails/data/useApplication';
import { PageHeader } from '~/components/PageHeader';
import { useApplicationDetailPageProps } from '~/pages/ApplicationDetails/data/useApplicationDetailPageProps';
import { ApplicationDetailsIcon } from './../assets';

type ApplicationDetailsHeadingProps = {
  application: Application;
};

export function ApplicationDetailsHeading(props: ApplicationDetailsHeadingProps) {
  const {
    initialProps: {
      referenceData: { statusMap },
    },
  } = useApplicationDetailPageProps();
  return (
    <PageHeader title="Application Details" icon={ApplicationDetailsIcon}>
      <Tag icon="fill" intent="primary" size="md">
        {statusMap[props.application.status]}
      </Tag>
    </PageHeader>
  );
}
