import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Outlet } from 'react-router-dom';

import { Appbar } from './Appbar';
import SchoolLogo from './SchoolLogo.svg';
import { Sidebar } from './Sidebar';
import { Breadcrumb } from './Breadcrumb';
import { PersonListOutline } from './../../icons';
import { LoadingPage } from '../../pages/LoadingPage';

import { routes, useMatches } from './../../Routes';
import { Content, FixedAppbar, FixedSidebar, FullHeightWidthLayout } from './styles';

const visibleRoutes = routes.filter((s) => !s.hidden);

export function Layout() {
  const auth = useAuth();
  const matches = useMatches();
  const crumbs = matches.filter((match) => Boolean(match.crumb)).map((match) => match.crumb) as string[];

  React.useEffect(() => {
    window.document.title = `${crumbs[crumbs.length - 1]} | AMS`;
  }, [crumbs]);

  if (auth.isLoading) return <LoadingPage />;
  if (!auth.isAuthenticated) return <> You are not authenticated </>;

  return (
    <FullHeightWidthLayout>
      {/* AppBar */}
      <FixedAppbar data-testid="header">
        <Appbar
          title="ACME University"
          logo={SchoolLogo}
          breadcrumb={
            <Breadcrumb>
              {crumbs.map((crumb) => (
                <Breadcrumb.Item key={crumb}>{crumb}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          }
        ></Appbar>
      </FixedAppbar>
      {/* Sidebar */}
      <FixedSidebar>
        <Sidebar>
          {visibleRoutes.map((route) => (
            <Sidebar.Item key={route.name} href={route.path} title={route.name} icon={PersonListOutline} />
          ))}
        </Sidebar>
      </FixedSidebar>
      {/* Content */}
      <Content>
        <Outlet />
      </Content>
    </FullHeightWidthLayout>
  );
}
