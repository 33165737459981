import React from 'react';
import { ButtonLink, Flex, Heading } from '@applyboard/crystal-ui';
import { ArrowLeftOutlineIcon, ChevronRightOutlineIcon } from '@applyboard/ui-icons';

type BreadcrumbProps = {
  children?: React.ReactNode[] | React.ReactNode;
};
export function Breadcrumb(props: BreadcrumbProps) {
  const items = React.Children.toArray(props.children);

  return (
    <Flex direction="row" align="center" gap={2}>
      <ButtonLink
        href="/"
        aria-label="back to home"
        emphasis="transparent"
        leadIcon={ArrowLeftOutlineIcon}
        size="md"
      ></ButtonLink>
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      {items.map((item, idx) => (
        <Item key={idx}>{item}</Item>
      ))}
    </Flex>
  );
}

type BreadcrumbItemProps = {
  children: string;
};
Breadcrumb.Item = function BreadcrumbItem(props: BreadcrumbItemProps) {
  return (
    <Heading level={2} variant="labelL" intent="secondary" contrast="mid">
      {props.children}
    </Heading>
  );
};

function Item(props: { children: React.ReactNode }) {
  return (
    <>
      <ChevronRightOutlineIcon color="secondary.default" size="md" />
      {props.children}
    </>
  );
}
