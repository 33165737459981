import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { uidNoDash, UnBoxArrayType } from '~/utils';

import { StaticProps, useStaticProps } from './StaticPropsContext';
import { queryParametersSchema, QueryParameters, QueryParametersKey, QueryParametersValue } from './../schema';
import { useFilterSummary, FilterSummaryItem } from './../hooks/useFilterSummary';
import { useEventTracking } from './../hooks/useEventTracking';
import { useSearchHistory } from './../hooks/useSearchHistory';

export type ChangeSource =
  | 'URL'
  | 'Pagination'
  | 'PrimaryFilter'
  | 'FilterSummary'
  | 'AdvancedFilter'
  | 'StudentEligibility';

export interface QueryParameter {
  rnd: string;
  queryId: string;
  queryParameters: QueryParameters;
}

export type QueryParameterContext = QueryParameter & {
  queryParameterSummary: Array<FilterSummaryItem<QueryParametersKey>>;

  resetQueryParameters: () => void;
  setQueryParameters: (source: ChangeSource, changes: Partial<QueryParameters>) => void;
  removeQueryParameterValue: <Key extends QueryParametersKey>(
    key: Key,
    removedValue: UnBoxArrayType<QueryParametersValue<Key>>
  ) => void;
};

const queryParameterContext = createContext<QueryParameterContext | undefined>(undefined);

interface QueryParameterContextProviderProps {
  children: React.ReactNode;
}
export const QueryParameterContextProvider = ({ children }: QueryParameterContextProviderProps) => {
  const { trackEvent } = useEventTracking('Filter Parameters');
  const staticProps = useStaticProps();

  const [{ queryParameters, rnd, queryId }, setSearchQueryParameter] = useState<{
    rnd: string;
    queryId: string;
    queryParameters: QueryParameters;
  }>(() => ({
    rnd: uidNoDash(),
    queryId: uidNoDash(),
    queryParameters: staticProps.defaultQueryParameters,
  }));

  const { parse, push, urlRnd } = useSearchHistory(staticProps);
  const { filterSummaries } = useFilterSummary({ queryParameters, staticProps });

  const setQueryParameters = useCallback(
    (source: ChangeSource, changes: Partial<QueryParameters>) => {
      const rnd = uidNoDash();
      const sanitizedParameters = sanitizeParams(staticProps, {
        ...queryParameters,
        ...changes,
      });

      // we will generate a new id for every filter parameter change
      //  except page_size and pageNumber
      let qid = queryId;
      const changeSourceKeys = Object.keys(changes);
      if (changeSourceKeys.filter((s) => s !== 'pageNumber' && s !== 'pageSize').length > 0) {
        qid = uidNoDash();
      }

      const updatedParameters = { rnd, queryParameters: sanitizedParameters, queryId: qid };
      setSearchQueryParameter(updatedParameters);
      trackEvent({
        event: 'Filter Changed',
        data: {
          rnd,
          source,
          queryId,
          parameter_keys: [...Object.keys(updatedParameters.queryParameters), 'pageNumber'],
          prv_query_parameters: queryParameters,
          new_query_parameters: updatedParameters,
        },
      });
      push(rnd, sanitizedParameters);
    },
    [staticProps, queryParameters, push, trackEvent, queryId]
  );
  const resetQueryParameters = useCallback(() => {
    setQueryParameters('FilterSummary', {
      ...staticProps.defaultQueryParameters,
      pageSize: queryParameters.pageSize, // we should keep the selected pageSize
    });
  }, [setQueryParameters, staticProps, queryParameters]);
  const removeQueryParameterValue = useCallback(
    <Key extends QueryParametersKey>(key: Key, removedValue: UnBoxArrayType<QueryParametersValue<Key>>) => {
      const filterSchema = queryParametersSchema.get(key);
      if (!filterSchema.removeParam) return;

      const value = filterSchema.getValue({ queryParameters, staticProps });
      const defaultValue = filterSchema.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter });
      const result = filterSchema.removeParam({
        queryParameters,
        staticProps,
        defaultValue,
        value,
        removedValue,
      }) as Partial<typeof queryParameters> | undefined;
      if (!result) return;

      setQueryParameters('FilterSummary', { ...queryParameters, ...result, pageNumber: 1 });
    },
    [setQueryParameters, queryParameters, staticProps]
  );

  // whenever URL changes
  useEffect(() => {
    if (urlRnd === rnd) return;

    const sanitizedUrlQueryParameters = sanitizeParams(staticProps, parse());
    setSearchQueryParameter({
      queryId: uidNoDash(),
      rnd: urlRnd || rnd,
      queryParameters: sanitizedUrlQueryParameters,
    });
    //TODO: causing some issue on tests
    // trackEvent({
    //   event: 'Filter Changed',
    //   data: {
    //     rnd,
    //     source: 'URL',
    //     parameter_keys: [...Object.keys(sanitizedUrlQueryParameters), 'pageNumber'],
    //     new_query_parameters: sanitizedUrlQueryParameters,
    //   },
    // });
  }, [urlRnd, rnd, parse, staticProps]);

  const value = useMemo(
    () => ({
      rnd,
      queryId,

      queryParameters,
      queryParameterSummary: filterSummaries,

      setQueryParameters,
      resetQueryParameters,
      removeQueryParameterValue,
    }),
    [
      queryParameters,
      rnd,
      queryId,
      setQueryParameters,
      resetQueryParameters,
      removeQueryParameterValue,
      filterSummaries,
    ]
  );

  return <queryParameterContext.Provider value={value}>{children}</queryParameterContext.Provider>;
};

export const useQueryParameter = () => {
  const value = useContext(queryParameterContext);
  if (value === undefined) {
    throw new Error('useQueryParameterContext must be used within a QueryParameterContextProvider');
  }
  return value;
};

const sanitizeParams = (staticProps: StaticProps, queryParameters: QueryParameters) => {
  const result = { ...queryParameters };
  queryParametersSchema.parameters.forEach((parameter) => {
    if (!parameter.sanitize) return;
    const value = parameter.getValue({ staticProps, queryParameters });
    const defaultValue = parameter.getDefaultValue({ initialQueryParameters: staticProps.initialQueryParameter });
    const params = parameter.sanitize({ value, staticProps, defaultValue, queryParameters });
    Object.assign(result, params);
  });

  return result;
};
