import { ExpandableCard } from '../../../components/uie-fix/ExpandableCard';
import { StudentOutlineIcon } from '@applyboard/ui-icons';

export function ApplicantEducation() {
  return (
    <ExpandableCard icon={StudentOutlineIcon} title="Education">
      <></>
    </ExpandableCard>
  );
}
