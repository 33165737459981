import React from 'react';
import styled from '@emotion/styled';
import { Accordion } from '@applyboard/crystal-ui';
import { IconType } from '../../utils';

export type ExpandableCardProps = {
  icon: IconType;
  title: string;
  children: React.ReactNode;
};

export function ExpandableCard(props: ExpandableCardProps) {
  return (
    <VisualBugFix>
      <Accordion>
        <Accordion.Item autoOpen={true} id={props.title} icon={props.icon} title={props.title}>
          {props.children}
        </Accordion.Item>
      </Accordion>
    </VisualBugFix>
  );
}

const VisualBugFix = styled.div({
  // button
  '& > div > div > button': {
    padding: '20px',
  },
  // the content
  '& > div > div > div': {
    padding: '20px',
    paddingTop: '5px',
    borderTopColor: 'transparent',
  },
});
