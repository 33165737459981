import { config } from '../config';
import { useQuery } from '@tanstack/react-query';

import { request } from '../lib/useRequest';

export type AppProps = {
  oauth: {
    authority: string;
    clientId: string;
  };
};

type RawAppPropsResponse = {
  userPoolId: string;
  userPoolClientId: string;
  region: string;
};

export function useAppProps() {
  const { data, isLoading, isError } = useQuery(
    ['v1_spa_props'],
    async (): Promise<AppProps> => {
      const response = await request<RawAppPropsResponse>(`${config.apiHost}/private/v1/spa_props`, {
        method: 'GET',
        isExpectedResponse,
      });

      return {
        oauth: {
          authority: `https://cognito-idp.${response.region}.amazonaws.com/${response.userPoolId}`,
          clientId: response.userPoolClientId,
        },
      };
    },
    {
      refetchOnMount: false,
      keepPreviousData: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    }
  );

  return {
    appProps: data,
    isAppPropsError: isError,
    isAppPropsLoading: isLoading,
  };
}

function isExpectedResponse(res: unknown): res is RawAppPropsResponse {
  return Boolean(res && typeof res === 'object');
}
