import { useEffect } from 'react';
import { AuthProvider, useAuth, hasAuthParams, AuthProviderProps } from 'react-oidc-context';

export { useAuth } from 'react-oidc-context';

import { useAppProps } from '~/lib/useAppProps';

function AutoSignIn(props: { children: JSX.Element }) {
  const auth = useAuth();

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth]);

  return props.children;
}

const onSigninCallback = (): void => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export function Authenticator(props: { children: JSX.Element }) {
  const appProps = useAppProps();

  const authConfig: AuthProviderProps = {
    authority: appProps.oauth.authority,
    client_id: appProps.oauth.clientId,
    redirect_uri: `${window.location.protocol}//${window.location.host}/applications`,
    post_logout_redirect_uri: `${window.location.protocol}//${window.location.host}/login`,
    onSigninCallback: onSigninCallback,
  };
  return (
    <AuthProvider {...authConfig}>
      <AutoSignIn>{props.children}</AutoSignIn>
    </AuthProvider>
  );
}
