import { Divider, Flex } from '@applyboard/crystal-ui';
import { ExpandableCard } from './../../../components/uie-fix/ExpandableCard';
import { DescriptionList } from '../../../components/uie-fix/DescriptionList';

import { LanguageProficiency as LanguageProficiencyModel } from '../data/useApplication';
import { LanguageProficiencyOutline } from '../../../icons';
import { formatDate } from '../../../utils';

type LanguageProficiencyProps = {
  languageProficiency: LanguageProficiencyModel;
};
export function LanguageProficiency(props: LanguageProficiencyProps) {
  const { languageProficiency } = props;
  return (
    <ExpandableCard icon={LanguageProficiencyOutline} title="Language Proficiency">
      <DescriptionList aria-label="Language Test Information" variant="grid">
        <DescriptionList.Item
          align="start"
          justify="space-between"
          term="Test Type:"
          details={languageProficiency.title}
        />
        <DescriptionList.Item
          align="start"
          justify="space-between"
          term="Test Date:"
          details={formatDate(languageProficiency.dateOfCompletion)}
        />
        <DescriptionList.Item
          align="start"
          justify="space-between"
          term="TRF Number:"
          details={languageProficiency.referenceNumber}
        />
      </DescriptionList>
      <Divider />
      <DescriptionList direction="horizontal">
        <DescriptionList.Item
          align="start"
          term={languageProficiency.overallScoreTitle}
          details={languageProficiency.overallScore.toFixed(1).toString()}
        />
      </DescriptionList>
      <Flex gap={5} align="start">
        <DescriptionList direction="horizontal">
          {languageProficiency.subScores?.map((s) => (
            <DescriptionList.Item
              align="start"
              key={s.scoreCode}
              term={s.scoreTitle}
              details={s.scoreValue.toFixed(1).toString()}
            />
          ))}
        </DescriptionList>
      </Flex>
    </ExpandableCard>
  );
}
