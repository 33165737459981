import styled from '@emotion/styled';
import {
  DescriptionList as CyDescriptionList,
  DescriptionListProps as CyDescriptionListProps,
} from '@applyboard/crystal-ui';

type DescriptionListProps = {
  direction?: 'vertical' | 'horizontal';
} & CyDescriptionListProps;
export function DescriptionList(props: DescriptionListProps) {
  const { direction = 'vertical' } = props;

  if (direction === 'vertical') {
    return (
      <VerticalVisualBugFix>
        <CyDescriptionList {...props} />
      </VerticalVisualBugFix>
    );
  } else {
    return (
      <HorizontalVisualBugFix>
        <CyDescriptionList {...props} />
      </HorizontalVisualBugFix>
    );
  }
}
DescriptionList.Item = CyDescriptionList.Item;

const VerticalVisualBugFix = styled.div({
  // button
  '& > dl': {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
    },
  },
});

const HorizontalVisualBugFix = styled.div({
  // button
  '& > dl': {
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      '& > dd': {
        fontWeight: 'bold',
      },
    },
  },
});
