import { Flex, List, Tag, Text } from '@applyboard/crystal-ui';
import { ExpandableCard } from '../../../components/uie-fix/ExpandableCard';

import { Program } from '../data/useApplication';
import { ProgramOutline } from '../../../icons';

type ProgramsSelectedProps = {
  programs: Program[];
};

export function ProgramsSelected(props: ProgramsSelectedProps) {
  const { programs } = props;
  return (
    <ExpandableCard icon={ProgramOutline} title="Selected Program(s)">
      <List aria-label="Program List">
        {programs.map((program, idx) => (
          <Flex key={program.programId} gap={3} align="center">
            <Text align="left" contrast="mid" intent="secondary" variant="bodyM">
              #{idx + 1}
            </Text>
            <Text align="left" contrast="high" intent="secondary" variant="bodyM">
              {program.programId} | {program.programTitle} | {program.programCampus}
            </Text>
            <Tag intent="secondary" size="sm">
              {program.programIntakeTermTitle}
            </Tag>
          </Flex>
        ))}
      </List>
    </ExpandableCard>
  );
}
