// Temporarily, will be replaced by UIE version later
import React from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { CheckMarkOutlineIcon } from '@applyboard/ui-icons';
import { Divider, Flex, Text } from '@applyboard/crystal-ui';

type Status = 'pass' | 'active' | 'passive';

type StepperProps = { children: React.ReactNode };
export function Stepper(props: StepperProps) {
  const childrens = React.Children.toArray(props.children);
  return (
    <Flex gap={3} align="start" justify="start">
      {childrens.map((c, idx) => (
        <Flex.Item key={idx} grow={1} align="center">
          <Flex gap={3} direction="row" justify="between" align="start">
            {c}
            {idx < childrens.length - 1 && (
              <Flex.Item grow={2}>
                <Divider />
              </Flex.Item>
            )}
          </Flex>
        </Flex.Item>
      ))}
    </Flex>
  );
}

type StepperItemProps = {
  title: string;
  status: Status;
};
Stepper.Item = function StepperItem(props: StepperItemProps) {
  return (
    <Flex align="center" justify="start" gap={2}>
      <StepperIcon status={props.status}>
        {props.status === 'pass' && <CheckMarkOutlineIcon color="primary.light" />}
      </StepperIcon>
      <Text intent="secondary" variant="labelM">
        {props.title}
      </Text>
    </Flex>
  );
};

type StepperIconProps = {
  status: Status;
};
const getStepColor = (theme: Theme, status: Status) => {
  switch (status) {
    case 'active':
      return [theme.colors.iconPrimary, 'transparent'];
    case 'pass':
      return [theme.colors.iconPositive, 'transparent'];
    case 'passive':
      return ['transparent', theme.colors.borderSecondary];
    default:
      return ['transparent', 'transparent'];
  }
};
const StepperIcon = styled.div<StepperIconProps>((props) => {
  const [bgColor, borderColor] = getStepColor(props.theme, props.status);
  return {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: bgColor,
    border: `2px solid ${borderColor}`,
    borderRadius: '100%',
  };
});
