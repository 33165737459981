type KeyValueHash = Record<string, string>;

export type ReferenceData = {
  nationalities: KeyValueHash;
  statusMap: KeyValueHash;
};
export type useApplicationDetailPageProps = {
  referenceData: ReferenceData;
};

export function useApplicationDetailPageProps() {
  const initialProps: useApplicationDetailPageProps = {
    referenceData: {
      nationalities: {
        TR: 'Turkish',
        CA: 'Canada',
        US: 'United States of America',
        IE: 'Ireland',
        EU: 'European Union',
        GB: 'United Kingdom',
        AU: 'Australia',
        IN: 'India',
      },
      statusMap: {
        SUBMITTED: 'Ready for Screening',
        SCREENED: 'Ready for Assessment',
        OFFER_ACCEPTED: 'Initial Offer Issued',
        REJECTED: 'Declined',
      },
    },
  };
  return {
    initialPropsError: false,
    isLoadingInitialProps: true,
    initialProps,
  };
}
