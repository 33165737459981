import { Flex, Table, Tag, ButtonLink, Text } from '@applyboard/crystal-ui';
import { Application } from './../data/useApplications';
import { ChevronRightOutlineIcon } from './../../../icons';
import { Flag } from './../../../components/Flag';
import { useSearchResult, useStaticProps } from './../context';
import { formatDate } from '~/utils';

export function ApplicationsTable() {
  const { applications, isFetchingApplications } = useSearchResult();
  const { referenceData } = useStaticProps();
  const { statuses, nationalities } = referenceData;

  return (
    <Flex direction="column">
      <Table
        columns={[
          {
            accessorKey: 'id',
            header: 'App. ID',
          },
          {
            accessorKey: 'applicantName',
            header: 'Applicant Name',
            cell: (application: Application) => {
              const applicant = application.applicant;
              const middleName = applicant.middleName ? ` ${applicant.middleName}` : '';
              const lastName = applicant.lastName ? ` ${applicant.lastName}` : '';
              return <Table.TextCell>{`${applicant.firstName}${middleName}${lastName}`}</Table.TextCell>;
            },
          },
          {
            accessorKey: 'nationality',
            header: 'Nationality',
            cell: (application: Application) => {
              return (
                <Table.Cell>
                  <Flex align="center">
                    <Flag nationalityCode={application.applicant.nationality} />
                    <Table.TextCell>{nationalities[application.applicant.nationality].alpha3Code || ''}</Table.TextCell>
                  </Flex>
                </Table.Cell>
              );
            },
          },
          {
            accessorKey: 'intakeTerm',
            header: 'Program Intake',
            cell: (application: Application) => {
              return (
                <Flex direction="column" gap={1} p={2} align="start">
                  <Text as="div" contrast="high" id="" intent="secondary" variant="bodyM" overflow="ellipsis">
                    {application.programIntake.program.name}
                  </Text>
                  <Tag intent="secondary" size="sm">
                    {application.programIntake.intakeTerm.title}
                  </Tag>
                </Flex>
              );
            },
          },
          {
            accessorKey: 'dateSubmitted',
            header: 'Date Submitted',
            cell: (application: Application) => {
              return <Table.TextCell>{formatDate(application.dateSubmitted, { month: 'short' })}</Table.TextCell>;
            },
          },
          {
            accessorKey: 'status',
            header: 'Status',
            cell: (application: Application) => {
              return (
                <Tag icon="fill" intent={statuses[application.status].intention} size="sm">
                  {statuses[application.status].label}
                </Tag>
              );
            },
          },
          {
            accessorKey: 'link',
            header: '',
            cell: (application: Application) => {
              return (
                <ButtonLink
                  aria-label="Application Link"
                  emphasis="transparent"
                  href={`/applications/${application.id}`}
                  intent="secondary"
                  leadIcon={() => {
                    return <ChevronRightOutlineIcon size="md" />;
                  }}
                  size="sm"
                  width="hug"
                />
              );
            },
          },
        ]}
        data={applications}
        loading={isFetchingApplications}
      />
    </Flex>
  );
}
