import React from 'react';
import { MultiSelect } from '@applyboard/crystal-ui';
import { useFilterField } from '../../hooks/useFilterField';

export const NationalityFilter = () => {
  const {
    value,
    setValue,
    staticProps: { referenceData },
  } = useFilterField('nationality', 'PrimaryFilter');

  const nationalities = React.useMemo(
    () => Object.entries(referenceData.nationalities).map(([k, v]) => ({ label: v.nationalityLabel, value: k })),
    [referenceData.nationalities]
  );

  return (
    <MultiSelect
      value={value}
      aria-label="Nationality"
      placeholder="Nationality"
      onChange={setValue}
      emphasis="highlighted"
      placeholderVariant="indicator"
    >
      {nationalities.map((nationality) => (
        <MultiSelect.Option label={nationality.label} value={nationality.value} key={nationality.value} />
      ))}
    </MultiSelect>
  );
};
