import { Stepper } from './../../../components/Stepper';
import { ApplicationStatus } from '../../../data/types';

type ApplicationStateStepperProps = {
  applicationStatus: ApplicationStatus;
};

const decidedStates: ApplicationStatus[] = ['OFFER_ACCEPTED', 'REJECTED'];

export function ApplicationStateStepper({ applicationStatus }: ApplicationStateStepperProps) {
  const screeningStatus = decidedStates.includes(applicationStatus) ? 'pass' : 'active';
  const decisionStatus = decidedStates.includes(applicationStatus) ? 'active' : 'passive';

  return (
    <Stepper>
      <Stepper.Item title="Submission" status="pass" />
      <Stepper.Item title="Screening" status={screeningStatus} />
      <Stepper.Item title="Decision" status={decisionStatus} />
    </Stepper>
  );
}
