import styled from '@emotion/styled';
import { Flex, Button, Avatar, Divider } from '@applyboard/crystal-ui';
import { AccountCircleOutlineIcon, MenuOutlineIcon } from '../../icons';

import { SidebarExpandWidth } from './consts';

type AppbarProps = {
  logo: string;
  title: string;
  breadcrumb: React.ReactNode;
};
export function Appbar(props: AppbarProps) {
  return (
    <Flex direction="row" gap={0} p={0}>
      <SidebarContainer>
        <Flex direction="row" align="center" p={2}>
          <Button aria-label="Main Menu" emphasis="transparent" leadIcon={MenuOutlineIcon} intent="primary" />
          <img alt={props.title} src={props.logo} />
        </Flex>
      </SidebarContainer>
      <Divider direction="vertical" />
      <Flex.Item grow={1}>
        <Flex justify="between" p={2}>
          {props.breadcrumb}
          <Avatar alt="User" emphasis="highlighted" icon={AccountCircleOutlineIcon} intent="primary" size="sm" />
        </Flex>
      </Flex.Item>
    </Flex>
  );
}

const SidebarContainer = styled.div({
  width: `${SidebarExpandWidth - 1}px`,
});
