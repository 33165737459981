import styled from '@emotion/styled';
import { ButtonLinkProps, ButtonLink } from '@applyboard/crystal-ui';

export function SidebarLinkButton(props: ButtonLinkProps & { href: string }) {
  return (
    <Styles>
      <ButtonLink {...props} />
    </Styles>
  );
}

const Styles = styled.div({
  a: {
    paddingInline: '12px',
    '> div': {
      justifyContent: 'start',
      '> div': {
        gap: '8px',
      },
    },
    svg: {
      width: '24px',
      height: '24px',
    },
  },
});
