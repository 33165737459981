import { NetworkError, HttpError, UnexpectedResponseError } from '../../errors';

export type RequestParams<T> = {
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
  body?: FormData | File | object | null;
  defaultResponse?: T;
  isExpectedResponse: (res: unknown) => res is T;
  throwError?: ((res: Response) => void) | ((res: Response) => Promise<void>);
  handleRedirect?: 'follow' | 'error' | 'manual';
  authorization?: string;
};

export async function request<T = unknown>(endpoint: string, params: RequestParams<T>): Promise<T> {
  const requestHeaders = new Headers({
    'Content-Type': 'application/json',
  });

  let res: Response;

  if (params.authorization) {
    requestHeaders.set('Authorization', `Bearer ${params.authorization}`);
  }

  try {
    res = await fetch(endpoint, {
      method: params?.method || 'GET',
      headers: requestHeaders,
      body: JSON.stringify(params.body),
    });
  } catch (err) {
    throw new NetworkError();
  }

  if (res.status === 404 && params.defaultResponse) {
    return params.defaultResponse;
  }

  if (!res.ok) {
    const errorData = await res.json().catch(() => null);
    if (res.status === 401) {
      throw new HttpError(res.status, errorData?.message);
    }
    throw new HttpError(res.status, findErrorMessage(errorData));
  }

  const dataStr = await res.text();

  if (!dataStr) {
    if (!params.isExpectedResponse(null)) {
      throw new UnexpectedResponseError(res.status, 'Unexpected response');
    }
    return {} as T;
  }

  const data: T = JSON.parse(dataStr);

  if (!params.isExpectedResponse(data)) {
    throw new UnexpectedResponseError(res.status, 'Unexpected response');
  }

  return data;
}

/**
 * Retrieves the error message from a JSON object received from the server.
 * It assumes that an error already occurred and we just need to find the message.
 *
 * @param errorData
 * @return the error that server sent us. Defaults to 'An error occurred' if we can't find anything.
 */
function findErrorMessage(errorData?: { message: string }): string {
  return errorData?.message || 'An error occurred';
}
