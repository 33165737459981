import { Flex, GridAlpha } from '@applyboard/crystal-ui';
import {
  ApplicationDetailsHeading,
  ApplicantInformation,
  ProgramsSelected,
  ApplicantEducation,
  LanguageProficiency,
  ApplicationStateStepper,
  SupportingDocuments,
} from './../components';
import { Application } from '../data/useApplication';

type DesktopLayoutProps = { application: Application };
export function DesktopLayout(props: DesktopLayoutProps) {
  return (
    <Flex p={10} gap={3} direction="column">
      <ApplicationDetailsHeading application={props.application} />
      <ApplicationStateStepper applicationStatus={props.application.status} />
      <Flex pt={20} gap={12} align="stretch" direction="column">
        <ApplicantInformation applicant={props.application.applicant} />
        <GridAlpha columns="repeat(1fr)" areas={['left right']} columnGap={10}>
          <GridAlpha.Item areaName="left">
            <Flex gap={10} direction="column">
              <ProgramsSelected programs={props.application.programsSelected} />
              <ApplicantEducation />
            </Flex>
          </GridAlpha.Item>
          <GridAlpha.Item areaName="right">
            <Flex gap={10} direction="column">
              {props.application.languageProficiency && (
                <LanguageProficiency languageProficiency={props.application.languageProficiency} />
              )}
              <SupportingDocuments supportingDocuments={props.application.supportingDocuments} />
            </Flex>
          </GridAlpha.Item>
        </GridAlpha>
      </Flex>
    </Flex>
  );
}
