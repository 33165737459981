import { useQuery } from '@tanstack/react-query';
import { JsonApiResource, JsonApiResponse } from '~/data/shared';
import { ApplicationStatus } from '~/data/types';
import { hasKey } from '~/utils';
import { config } from '~/config';
import { useRequest } from '~/lib/useRequest';

export type Applicant = {
  firstName: string;
  middleName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  gender?: 'male' | 'female' | 'other';
  nationality?: string;
};

export type LanguageProficiency = {
  type: 'IELTS' | 'PTE' | 'TOEFL';
  title: string;
  language: 'en';
  referenceNumber: string;
  dateOfCompletion: Date;
  overallScoreTitle: string;
  overallScore: number;
  subScores?: Array<{
    scoreCode: string;
    scoreTitle: string;
    scoreValue: number;
  }>;
};

export type Program = {
  programId: string;
  programTitle: string;
  programCampus: string;
  programIntakeTermTitle: string;
};

export type SupportingDocument = {
  title: string;
  attachment: {
    name: string;
    objectKey: string;
    validationStatus?: {
      validationState?: 'VALID' | 'INVALID' | 'UNKNOWN';
      reason?: string;
    };
  };
};

export type Application = {
  id: string;
  status: ApplicationStatus;
  applicant: Applicant;
  programsSelected: Program[];
  languageProficiency?: LanguageProficiency;
  supportingDocuments: SupportingDocument[];
};

type RawApplicant = Omit<Applicant, 'dateOfBirth'> & { dateOfBirth?: string };
type RawLanguageProficiency = Omit<LanguageProficiency, 'dateOfCompletion'> & {
  dateOfCompletion: string;
};
type RawApplication = JsonApiResource<
  'application',
  Omit<Application, 'id' | 'applicant' | 'languageProficiency'> & {
    applicant: RawApplicant;
    languageProficiency: RawLanguageProficiency;
  }
>;
export type RawApplicationResponse = JsonApiResponse<RawApplication>;

export function useApplication(id: string) {
  const request = useRequest();
  const { isLoading, isFetching, error, data } = useQuery([`application-${id}`], async () => {
    try {
      const response = await request<RawApplicationResponse>(`${config.apiHost}/private/v1/applications/${id}`, {
        method: 'GET',
        isExpectedResponse,
      });
      return {
        id: response.data.id,
        status: response.data.attributes.status,
        applicant: {
          ...response.data.attributes.applicant,
          dateOfBirth:
            response.data.attributes.applicant.dateOfBirth &&
            new Date(`${response.data.attributes.applicant.dateOfBirth}T00:00:00`),
        },
        programsSelected: response.data.attributes.programsSelected,
        supportingDocuments: response.data.attributes.supportingDocuments,
        languageProficiency: response.data.attributes.languageProficiency && {
          ...response.data.attributes.languageProficiency,
          dateOfCompletion:
            response.data.attributes.languageProficiency &&
            new Date(`${response.data.attributes.languageProficiency.dateOfCompletion}T00:00:00`),
        },
      } as Application;
    } catch (e) {
      return null;
    }
  });

  return {
    isLoadingApplication: isLoading,
    isFetchingApplication: isFetching,
    applicationError: error,
    application: data,
  };
}

function isExpectedResponse(res: unknown): res is RawApplicationResponse {
  return Boolean(res && typeof res === 'object' && hasKey('data', res));
}
