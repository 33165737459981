import { useParams } from 'react-router-dom';
import { useApplication } from './data/useApplication';
import { DesktopLayout } from './layouts/DesktopLayout';
import { Flex, Spinner, Text } from '@applyboard/crystal-ui';

export function ApplicationDetailsPage() {
  const applicationId = useApplicationId();
  const { application, isLoadingApplication } = useApplication(applicationId); // applicationId always provided

  if (isLoadingApplication) {
    return (
      <Flex p={5} gap={5} align="center" direction="column" role="progressbar">
        <Spinner size="xl" />
      </Flex>
    );
  }

  // we need a UI for the 404 page
  if (!isLoadingApplication && !application) {
    return (
      <Flex p={5} gap={5} align="center" direction="column">
        <Text>Application not found.</Text>
      </Flex>
    );
  }

  // should not happen at any case
  if (!application) return null;

  return <DesktopLayout application={application} />;
}

function useApplicationId() {
  const { id } = useParams() as { id: string };
  return id;
}
