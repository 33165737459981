// TODO: should be addressed by UIE

import styled from '@emotion/styled';
import { AppBarHeight, SidebarExpandWidth } from './consts';

export const FullHeightWidthLayout = styled.div({
  minHeight: '100vh',
  minWidth: '100vw',
});
export const FixedAppbar = styled.div(({ theme }) => ({
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1720,
  width: '100%',
  position: 'fixed',
  height: `${AppBarHeight}`,
  backgroundColor: theme.colors.bgRegular,
  borderBottom: `1px solid ${theme.colors.bgSecondaryPressed}`,
}));
export const FixedSidebar = styled.div(({ theme }) => ({
  left: 0,
  bottom: 0,
  zIndex: 1720,
  position: 'fixed',
  top: AppBarHeight,
  width: `${SidebarExpandWidth}px`,
  borderRight: `1px solid ${theme.colors.bgSecondaryPressed}`,
}));
export const Content = styled.div({
  marginTop: AppBarHeight,
  marginLeft: `${SidebarExpandWidth}px`,
});
