import React from 'react';
import { Flex, Text } from '@applyboard/crystal-ui';

import { Flag } from '../../../components/Flag';
import { PersonOutlineIcon } from '@applyboard/ui-icons';

import { ExpandableCard } from './../../../components/uie-fix/ExpandableCard';
import { DescriptionList } from '../../../components/uie-fix/DescriptionList';

import { formatDate } from '../../../utils';
import { Applicant } from '../data/useApplication';
import { useApplicationDetailPageProps } from '../data/useApplicationDetailPageProps';

type ApplicantInformationProps = {
  applicant: Applicant;
};
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function ApplicantInformation(props: ApplicantInformationProps) {
  const { applicant } = props;
  const {
    initialProps: {
      referenceData: { nationalities },
    },
  } = useApplicationDetailPageProps();
  const NationalityCard = React.useMemo(() => {
    return (
      applicant?.nationality && (
        <Flex gap={5}>
          <Flag nationalityCode={applicant.nationality} />
          <Text> {nationalities[applicant.nationality]} </Text>
        </Flex>
      )
    );
  }, [applicant, nationalities]);

  return (
    <ExpandableCard icon={PersonOutlineIcon} title="Applicant Information">
      <Flex justify="start" gap={20}>
        <DescriptionList aria-label="Applicant Information" variant="grid">
          <DescriptionList.Item align="start" justify="start" term="First name:" details={applicant.firstName} />
          <DescriptionList.Item
            align="start"
            justify="start"
            term="Date of birth:"
            details={formatDate(applicant.dateOfBirth)}
          />
          <DescriptionList.Item
            align="start"
            justify="start"
            term="Gender:"
            details={capitalizeFirstLetter(applicant.gender || '')}
          />
        </DescriptionList>
        <DescriptionList aria-label="Applicant Information" variant="grid">
          <DescriptionList.Item align="start" justify="start" term="Last name:" details={applicant.lastName || ''} />
          <DescriptionList.Item align="start" justify="start" term="Nationality:" details={NationalityCard} />
        </DescriptionList>
        <DescriptionList aria-label="Applicant Information" variant="grid">
          <DescriptionList.Item align="start" justify="start" term="Email: " details={applicant.emailAddress || ''} />
          <DescriptionList.Item
            align="start"
            justify="start"
            term="Phone number:"
            details={applicant.phoneNumber || ''}
          />
        </DescriptionList>
      </Flex>
    </ExpandableCard>
  );
}
