import { ButtonLink, Flex } from '@applyboard/crystal-ui';
import { ExpandableCard } from './../../../components/uie-fix/ExpandableCard';

import { SupportingDocument } from '../data/useApplication';
import { SupportDocumentsOutline } from '../../../icons';
import { CheckCircleFillIcon, OpenInNewOutlineIcon, MinusOutlineIcon } from '@applyboard/ui-icons';

type SupportDocumentsProps = {
  supportingDocuments: SupportingDocument[];
};

function onClick(event: Event | undefined, url: string) {
  event?.preventDefault();
  window.open(url, '_blank');
}

export function SupportingDocuments({ supportingDocuments }: SupportDocumentsProps) {
  return (
    <ExpandableCard icon={SupportDocumentsOutline} title="Supporting Documents">
      <Flex gap={1} direction="column">
        {supportingDocuments.map(function (d, index) {
          return (
            <ButtonLink
              aria-label={`Download ${d.title} document`}
              key={index}
              emphasis="highlighted"
              href={d.attachment.objectKey}
              intent="positive"
              leadIcon={OpenInNewOutlineIcon}
              onClick={() => onClick(event, d.attachment.objectKey)}
              size="lg"
              trailIcon={
                d.attachment.validationStatus?.validationState === 'VALID' ? CheckCircleFillIcon : MinusOutlineIcon
              }
              width="fill"
            >
              {d.title}
            </ButtonLink>
          );
        })}
      </Flex>
    </ExpandableCard>
  );
}
