import { QueryParametersSchema, QueryParameterSchema, QueryParametersKey } from './types';

import { searchQueryParameter } from './searchQueryFilter';
import { pageNumberParameter } from './pageNumberParameter';
import { pageSizeParameter } from './pageSizeParameter';
import { statusFilterQueryFilter } from './statusFilterQueryFilter';
import { nationalityFilterQueryFilter } from './nationalityFilterQueryFilter';
import { submissionDateFilterSchema } from './submissionDateFilterSchema';

const schema: QueryParametersSchema = {
  searchQuery: searchQueryParameter,
  pageNumber: pageNumberParameter,
  pageSize: pageSizeParameter,
  status: statusFilterQueryFilter,
  nationality: nationalityFilterQueryFilter,
  submissionDate: submissionDateFilterSchema,
};

const parameters = Object.entries(schema).map(([key, parameter]) => {
  return { key: key as keyof QueryParametersSchema, ...(parameter as QueryParameterSchema<QueryParametersKey>) };
});

export const get = <Key extends QueryParametersKey>(key: Key): QueryParametersSchema[Key] => schema[key];

export const queryParametersSchema = {
  get,
  parameters,
};
