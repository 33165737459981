import { SvgIcon, SvgIconProps } from './SvgIcon';

export function ProgramOutline(props: Omit<SvgIconProps, 'children'>) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3H21C21.8284 3 22.5 3.67157 22.5 4.5V11.75H21.5V4.5C21.5 4.22386 21.2761 4 21 4H3C2.72386 4 2.5 4.22386 2.5 4.5V18C2.5 18.2761 2.72386 18.5 3 18.5H15.1316V19.5H3C2.17157 19.5 1.5 18.8284 1.5 18V4.5ZM8.5 6.5H15.5V7.5H8.5V6.5ZM17 9H7V10H17V9ZM8 11.5H15.5V12.5H8V11.5ZM10 15H4.5V16H10V15ZM17.7755 19.0464L17.5764 21.1973L19.2971 20.4336C19.4262 20.3763 19.5736 20.3763 19.7027 20.4336L21.4234 21.1973L21.2243 19.0465C20.7153 19.3352 20.1269 19.5 19.5 19.5C18.873 19.5 18.2845 19.3351 17.7755 19.0464ZM16.8424 18.2777L16.5806 21.1051C16.5102 21.8656 17.2839 22.4212 17.9821 22.1113L19.4999 21.4376L21.0178 22.1113C21.7159 22.4212 22.4896 21.8656 22.4192 21.1051L22.1574 18.2779C22.6826 17.6657 23 16.8699 23 16C23 14.067 21.433 12.5 19.5 12.5C17.567 12.5 16 14.067 16 16C16 16.8698 16.3173 17.6655 16.8424 18.2777ZM17 16C17 14.6193 18.1193 13.5 19.5 13.5C20.8807 13.5 22 14.6193 22 16C22 17.3807 20.8807 18.5 19.5 18.5C18.1193 18.5 17 17.3807 17 16Z"
        fill="#363B51"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4.5C1.5 3.67157 2.17157 3 3 3H21C21.8284 3 22.5 3.67157 22.5 4.5V11.75H21.5V4.5C21.5 4.22386 21.2761 4 21 4H3C2.72386 4 2.5 4.22386 2.5 4.5V18C2.5 18.2761 2.72386 18.5 3 18.5H15.1316V19.5H3C2.17157 19.5 1.5 18.8284 1.5 18V4.5ZM8.5 6.5H15.5V7.5H8.5V6.5ZM17 9H7V10H17V9ZM8 11.5H15.5V12.5H8V11.5ZM10 15H4.5V16H10V15ZM17.7755 19.0464L17.5764 21.1973L19.2971 20.4336C19.4262 20.3763 19.5736 20.3763 19.7027 20.4336L21.4234 21.1973L21.2243 19.0465C20.7153 19.3352 20.1269 19.5 19.5 19.5C18.873 19.5 18.2845 19.3351 17.7755 19.0464ZM16.8424 18.2777L16.5806 21.1051C16.5102 21.8656 17.2839 22.4212 17.9821 22.1113L19.4999 21.4376L21.0178 22.1113C21.7159 22.4212 22.4896 21.8656 22.4192 21.1051L22.1574 18.2779C22.6826 17.6657 23 16.8699 23 16C23 14.067 21.433 12.5 19.5 12.5C17.567 12.5 16 14.067 16 16C16 16.8698 16.3173 17.6655 16.8424 18.2777ZM17 16C17 14.6193 18.1193 13.5 19.5 13.5C20.8807 13.5 22 14.6193 22 16C22 17.3807 20.8807 18.5 19.5 18.5C18.1193 18.5 17 17.3807 17 16Z"
        fill="#363B51"
      />
    </SvgIcon>
  );
}
